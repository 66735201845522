import {
  HomeMetaTags,
  PartialHomeMetaTags,
} from '../../renderer/Head/home/HomeMetaRenderer'
import {
  CategoryMetaTags,
  PartialCategoryMetaTags,
} from '../../renderer/Head/category/CategoryMetaRenderer'
import {
  ArticleMetaTags,
  PartialArticleMetaTags,
} from '../../renderer/Head/article/ArticleMetaRenderer'
import { Tags } from '../../renderer/Head/MetaRenderer'
import {
  META_BINGEBOT_INDEX,
  META_GOOGLEBOT_INDEX,
} from '../../renderer/Head/utils'

const isPropEmpty = (prop: string | number | null): boolean => {
  if (typeof prop === 'string') {
    return prop === ''
  }

  // if the prop come as number, this should be treated as a valid prop
  return typeof prop === 'number' ? false : !Boolean(prop)
}

const removeEmptyProps = (obj: Tags) => {
  for (const propName in obj) {
    if (isPropEmpty(obj[propName])) {
      delete obj[propName]
    }
  }
  return obj
}

const removeEmptyRobotsData = (data: Tags) => {
  if (data.robots && !data.robots.includes('noindex')) {
    data.googlebot = META_GOOGLEBOT_INDEX
    data.bingbot = META_BINGEBOT_INDEX
  }
  removeEmptyProps(data)
  return data
}

export const HomeMetadataTransformer = (
  data: HomeMetaTags,
): PartialHomeMetaTags => {
  return {
    metaNameTags: removeEmptyRobotsData(data.metaNameTags),
    linkTags: removeEmptyProps(data.linkTags),
  }
}

export const ArticleMetadataTransformer = (
  data: ArticleMetaTags,
): PartialArticleMetaTags => {
  return {
    metaNameTags: removeEmptyRobotsData(data.metaNameTags),
    metaTags: removeEmptyProps(data.metaTags),
    linkTags: removeEmptyProps(data.linkTags),
  }
}

export const CategoryMetadataTransformer = (
  data: CategoryMetaTags,
): PartialCategoryMetaTags => {
  return {
    metaNameTags: removeEmptyRobotsData(data.metaNameTags),
    linkTags: removeEmptyProps(data.linkTags),
  }
}
