import { isString } from '../../string'
import { i18n } from '@thg-commerce/enterprise-core'

interface MultipleDistanceType {
  one: string
  other: string
}

type Token =
  | 'lessThanXSeconds'
  | 'xSeconds'
  | 'halfAMinute'
  | 'lessThanXMinutes'
  | 'xMinutes'
  | 'aboutXHours'
  | 'xHours'
  | 'xDays'
  | 'aboutXWeeks'
  | 'xWeeks'
  | 'aboutXMonths'
  | 'xMonths'
  | 'aboutXYears'
  | 'xYears'
  | 'overXYears'
  | 'almostXYears'

type DistanceTypes = { [token in Token]: string | MultipleDistanceType }

const formatDistanceLocale = (): DistanceTypes => ({
  lessThanXSeconds: 'general.date.distance.now.text',
  xSeconds: 'general.date.distance.now.text',
  halfAMinute: 'general.date.distance.now.text',
  lessThanXMinutes: 'general.date.distance.now.text',
  xMinutes: {
    one: 'general.date.minute.singular.text',
    other: 'general.date.minute.plural.text',
  },
  aboutXHours: {
    one: 'general.date.hour.singular.text',
    other: 'general.date.hour.plural.text',
  },
  xHours: {
    one: 'general.date.hour.singular.text',
    other: 'general.date.hour.plural.text',
  },
  xDays: {
    one: 'general.date.day.singular.text',
    other: 'general.date.day.plural.text',
  },
  aboutXWeeks: {
    one: 'general.date.week.singular.text',
    other: 'general.date.week.plural.text',
  },
  xWeeks: {
    one: 'general.date.week.singular.text',
    other: 'general.date.week.plural.text',
  },
  aboutXMonths: {
    one: 'general.date.month.singular.text',
    other: 'general.date.month.plural.text',
  },
  xMonths: {
    one: 'general.date.month.singular.text',
    other: 'general.date.month.plural.text',
  },
  aboutXYears: {
    one: 'general.date.year.singular.text',
    other: 'general.date.year.plural.text',
  },
  xYears: {
    one: 'general.date.year.singular.text',
    other: 'general.date.year.plural.text',
  },
  overXYears: {
    one: 'general.date.year.singular.text',
    other: 'general.date.year.plural.text',
  },
  almostXYears: {
    one: 'general.date.year.singular.text',
    other: 'general.date.year.plural.text',
  },
})

interface FormatDistanceOptions {
  addSuffix?: boolean
  comparison: number
}

export const formatDistance = (
  token: Token,
  count: number,
  options: FormatDistanceOptions = {
    comparison: 0,
  },
) => {
  let result
  const translations = formatDistanceLocale()
  const stringCount = (count as any) as string

  if (isString(translations[token])) {
    return i18n(translations[token] as string, stringCount)
  }

  const entry = translations[token] as MultipleDistanceType
  result = i18n(count === 1 ? entry.one : entry.other, stringCount)

  return options.addSuffix
    ? options.comparison > 0
      ? `${i18n('general.date.distance.prefixfuture.text')} ${result}`
      : `${result} ${i18n('general.date.distance.suffixpast.text')}`
    : result
}
