import Maybe from 'graphql/tsutils/Maybe'
import { Scalars } from '@thg-commerce/enterprise-network'

const META_GOOGLEBOT_INDEX =
  'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
const META_BINGEBOT_INDEX =
  'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'

const getRobotsMeta = (
  metaRobotsNoindex: string | Maybe<Scalars['String']>,
  metaRobotsNofollow: string | Maybe<Scalars['String']>,
): string => {
  return [metaRobotsNoindex, metaRobotsNofollow].join(', ')
}

export { META_GOOGLEBOT_INDEX, META_BINGEBOT_INDEX, getRobotsMeta }
