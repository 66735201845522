import * as React from 'react'

import { styled, spacing } from '@thg-commerce/enterprise-theme'
import {
  Separator,
  ArticlePreview,
  ArticlePreviewImage,
  ArticlePreviewProps,
  ArticlePreviewDirection,
} from '@thg-commerce/gravity-elements'
import { mq, BreakpointKey } from '@thg-commerce/gravity-theme'

export interface PostCardProps {
  article: ArticlePreviewProps
  'data-testid'?: string
  hideSeparator?: boolean
  direction?: ArticlePreviewDirection | ArticlePreviewDirection[]
  margin?: string | string[]
  alternativeImageStyle?: boolean
}

interface StyledArticleProps {
  alternativeImageStyle?: boolean
  margin?: string | string[]
}

const StyledSeparator = styled(Separator)`
  margin: ${spacing(2)} 0;
`

const StyledArticlePreview = styled(ArticlePreview)<StyledArticleProps>`
  ${ArticlePreviewImage} {
    ${(props) =>
      props.alternativeImageStyle
        ? `
        width: 503px;
        max-width: 100%;
        height: 100%;
        max-height: 240px;

        ${mq(props.theme.breakpointUtils.map, 'sm')} {
          width: 196px;
          max-width: inherit;
          height: 145px;
          max-height: 100%;
      }
    `
        : `
        width: 196px;
        max-width: 100%;
        height: 145px;
        max-height: 100%;
      `}

    ${(props) =>
      props.margin && Array.isArray(props.margin)
        ? props.margin
            .map(
              (margin, index) => `${mq(
                props.theme.breakpointUtils.map,
                props.theme.breakpointUtils.keys[index] as BreakpointKey,
              )} {
              margin-right: ${margin};
            }`,
            )
            .join('')
        : `
        margin-right: ${props.margin};
        `}
  }
`

export const PostCard = React.memo(
  ({
    article,
    'data-testid': testId,
    hideSeparator,
    direction,
    margin,
    alternativeImageStyle,
  }: PostCardProps) => {
    return (
      <React.Fragment>
        {!hideSeparator && <StyledSeparator />}
        <StyledArticlePreview
          {...article}
          image={{ ...article.image, lazy: true }}
          testId={testId}
          direction={direction || ['column', 'row']}
          alignment={['center', 'auto']}
          margin={margin || ['0px', `${spacing(2)}`, `${spacing(4)}`]}
          alternativeImageStyle={alternativeImageStyle || false}
        />
      </React.Fragment>
    )
  },
)
