import { renderer } from '@thg-commerce/enterprise-core/src/Head'

export interface Tags {
  [key: string]: string
}

export interface RendererTags {
  metaNameTags?: Tags
  metaTags?: Tags
  linkTags?: Tags
}

export const MetaRenderer = ({
  metaNameTags = {},
  metaTags = {},
  linkTags = {},
}: RendererTags) =>
  renderer([
    ...Object.entries(metaNameTags).map(([name, content]) => ({
      type: 'meta' as 'meta',
      props: {
        name,
        content,
      },
    })),
    ...Object.entries(metaTags).map(([property, content]) => ({
      type: 'meta' as 'meta',
      props: {
        property,
        content,
      },
    })),
    ...Object.entries(linkTags).map(([rel, href]) => ({
      type: 'link' as 'link',
      props: {
        rel,
        href,
      },
    })),
  ])
