import * as React from 'react'

import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

interface SectionTitleProps {
  children: React.ReactNode
  className?: string
}

const Title = styled.h3`
  ${Text('large1', 'alternate')};
  text-align: center;

  &:after {
    display: block;
    margin: ${spacing(1)} auto 0;
    width: 72px;
    height: 2px;
    background: ${(props) => props.theme.colors.palette.brand.base};
    content: '';
  }
`

export const SectionTitle = (props: SectionTitleProps) => (
  <Title className={props.className}>{props.children}</Title>
)
