import * as React from 'react'

import { i18n } from '@thg-commerce/enterprise-core'
import { AsgardPost } from '@thg-commerce/enterprise-network/types/enterpriseTypes'
import { ArticlePreviewDirection } from '@thg-commerce/gravity-elements'

import { ShowMoreButton } from './ShowMoreButton'
import { InfinitePostRenderer } from './InfinitePostRenderer'

export interface InfiniteScrollProps {
  postLimit: number
  offset: number
  posts: AsgardPost[]
  refetch: (offset: number) => void
  displayShowMore: boolean
  isAmp: boolean
  hideFirstSeparator?: boolean
  direction?: ArticlePreviewDirection | ArticlePreviewDirection[]
  margin?: string | string[]
  alternativeImageStyle?: boolean
}

export interface InfiniteScrollItems {
  title: string
  titleLink: string
  typeLink: string
  categoryLabel: string
  excerpt: string
  date: string
  author: string
  authorName: string
  featuredImage?: string
  excludedPosts: number[]
}

export const InfinitePosts = (props: InfiniteScrollProps) => {
  const i18nText = {
    showMore: i18n('widget.infinitescroll.showmore'),
  }

  if (!props.posts.length) {
    return null
  }

  const articleCards = props.posts.map((item, index) => {
    return (
      <InfinitePostRenderer
        item={item}
        isAmp={props.isAmp}
        key={item.id}
        hideSeparator={index === 0 ? props.hideFirstSeparator : false}
        direction={props.direction}
        margin={props.margin}
        alternativeImageStyle={props.alternativeImageStyle}
      />
    )
  })

  return (
    <React.Fragment>
      {articleCards}
      {props.displayShowMore && (
        <ShowMoreButton
          i18nText={{ showMore: i18nText.showMore }}
          offset={props.offset}
          limit={props.postLimit}
          onShowMore={(offset) => props.refetch(offset)}
        />
      )}
    </React.Fragment>
  )
}
