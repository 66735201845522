import React from 'react'

import { styled, spacing, Text } from '@thg-commerce/gravity-theme'
import { ButtonProps } from '../Button/Button'

export interface BlockAttributesProps {
  attributes: (string | ButtonProps)[]
  className?: string
}

const Container = styled.div`
  ${Text('xsmall', 'default')};
  color: ${(props) => props.theme.colors.palette.greys.dark};
`

const Attribute = styled.span`
  margin-right: ${spacing(1.5)};
  position: relative;

  &:after {
    content: '.';
    position: absolute;
    top: 0;
    margin-left: ${spacing(0.5)};
    transform: translateY(-25%);
  }

  &:last-child {
    margin-right: 0;

    &:after {
      content: '';
      margin-left: 0;
    }
  }
`

export const BlockAttributes = ({
  attributes,
  className,
}: BlockAttributesProps) => (
  <Container className={className}>
    {attributes.map((attribute, index) => (
      <Attribute key={index}>{attribute}</Attribute>
    ))}
  </Container>
)
