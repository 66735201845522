import { formatDistanceToNow as parentFormatDistanceToNow } from 'date-fns'
import { locale } from './locale'

const addLocaleToOptions = (options?: any) => ({
  ...options,
  locale: locale(),
})

export const formatDistanceToNow = (
  date: Date,
  options?: {
    includeSeconds?: boolean
    addSuffix?: boolean
    locale?: Object
  },
) => parentFormatDistanceToNow(date, addLocaleToOptions(options))
