import * as React from 'react'

import { Image } from '@thg-commerce/gravity-elements/Image'
import { ImageProps } from '@thg-commerce/gravity-elements/Image/Image'
import { Button } from '@thg-commerce/gravity-elements'
import {
  styled,
  spacing,
  css,
  Text,
  mq,
  BreakpointKey,
} from '@thg-commerce/gravity-theme'

import { PartialBy } from '../utils/typescript'
import { DrawFocus } from '../mixin/focus'
import { BlockAttributes } from '../BlockAttributes'

export type ArticlePreviewDirection = 'row' | 'column' | 'row-reverse'
type Alignment = 'center' | 'auto'
type HeadingSize = 'large' | 'small'

export interface ArticlePreviewProps {
  title: string
  i18nAuthorText: string
  category: string
  description?: string
  articleAge: string
  image: PartialBy<ImageProps, 'width' | 'height'>
  articleUrl: string
  categoryUrl: string
  authorUrl: string
  direction?: ArticlePreviewDirection | ArticlePreviewDirection[]
  className?: string
  testId?: string
  centerContent?: boolean
  headingSize?: HeadingSize
  alignment?: Alignment | Alignment[]
}

const CategoryLink = styled(Button)<{ headingSize?: string }>`
  display: block;
  margin-left: -2px;
  margin-bottom: ${spacing(2)};

  ${(props) =>
    props.headingSize === 'small' &&
    `
    margin-top: ${spacing(2)};
  `}
`

const AuthorLink = styled(Button)`
  display: inline-block;
`
// Custom style applied below within Container based on direction prop
const ArticleTitle = styled.h4`
  ${Text('bodyText', 'alternate')}
`

const ArticleDescription = styled.p`
  display: none;
  color: ${(props) => props.theme.colors.palette.greys.grey};

  ${(props) => `
    ${mq(props.theme.breakpointUtils.map, 'sm')} {
      display: inline-block;
      margin-bottom: ${spacing(2)};
    }`}
`

export const ArticlePreviewImage = styled(Image)`
  cursor: pointer;

  ${({ isAmp }) =>
    isAmp &&
    `
        width: 100%;
        height: auto;

        amp-img {
          min-width: 100%;
          max-width: 100%;
        }
  `}
`

const ArticleTitleContainer = styled.a`
  ${DrawFocus()}
  display: block;
  width: 100%;
  margin-bottom: ${spacing(2)};
  text-decoration: none;
`

const TextContainer = styled.div<{
  centerContent?: boolean
  direction?: ArticlePreviewDirection | ArticlePreviewDirection[]
  headingSize?: HeadingSize
}>`
  align-self: ${(props) => (props.centerContent ? 'center' : 'auto')};

  ${(props) =>
    props.headingSize !== 'large' &&
    `
      flex: 1;
    `}

  ${(props) =>
    props.direction === 'column' &&
    `
      ${mq(props.theme.breakpointUtils.map, 'md')} {
        max-width: 45%;
        margin-right: ${spacing(4)};
      }`}
`

const Container = styled.div<{
  flexDirection: ArticlePreviewDirection | ArticlePreviewDirection[]
  headingSize?: HeadingSize
  centerContent?: boolean
  alignment?: Alignment | Alignment[]
}>`
  display: flex;

  ${(props) =>
    props.flexDirection && Array.isArray(props.flexDirection)
      ? props.flexDirection
          .map(
            (direction, index) => `${mq(
              props.theme.breakpointUtils.map,
              props.theme.breakpointUtils.keys[index] as BreakpointKey,
            )} {
              flex-direction: ${direction};
            }`,
          )
          .join('')
      : `
      flex-direction: ${props.flexDirection};
      `}

  ${(props) =>
    props.alignment && Array.isArray(props.alignment)
      ? props.alignment
          .map(
            (alignment, index) => `${mq(
              props.theme.breakpointUtils.map,
              props.theme.breakpointUtils.keys[index] as BreakpointKey,
            )} {
              align-self: ${alignment};
            }`,
          )
          .join('')
      : `
        align-self: ${
          props.alignment
            ? props.alignment
            : props.centerContent
            ? 'center'
            : 'auto'
        } ;
      `}


  ${ArticlePreviewImage} {
    ${(props) =>
      props.flexDirection === 'row' &&
      `
      margin-right: ${spacing(2)};
      width: 196px;
      height: 145px;
      max-width: 100%;

      ${mq(props.theme.breakpointUtils.map, 'lg')} {
        margin-right: ${spacing(4)};
        }
    `}

    ${(props) =>
      props.headingSize === 'large' &&
      `
        width: 100%;
      `}
  }

  ${ArticleTitle} {
    ${(props) =>
      props.flexDirection === 'column' &&
      css`
        ${mq(props.theme.breakpointUtils.map, 'sm')} {
          ${Text('bodyText', 'alternate')}
        }
      `}
  }

  ${ArticleTitle} {
    ${(props) =>
      props.headingSize === 'large' &&
      css`
        ${mq(props.theme.breakpointUtils.map, 'md')} {
          ${Text('large2', 'alternate')}
        }
      `}
  }

  ${ArticleTitle} {
    ${(props) =>
      props.headingSize === 'small' &&
      css`
        ${mq(props.theme.breakpointUtils.map, 'md')} {
          ${Text('medium1', 'alternate')}
        }
      `}
  }

  ${TextContainer} {
    ${(props) => props.flexDirection === 'row' && `flex: 1;`}
  }
`

export const ArticlePreview = (props: ArticlePreviewProps) => {
  const direction = props.direction || 'column'
  const isAmp = props.image.isAmp

  const articleAuthorLink = (
    <AuthorLink
      sizing="regular"
      emphasis="low"
      renderedAs="a"
      href={props.authorUrl}
    >
      {props.i18nAuthorText}
    </AuthorLink>
  )

  const rowAmpSize = { width: 310, height: 190 }
  const columnAmpSize = { width: 196, height: 145 }
  const widthHeight = isAmp
    ? direction === 'row'
      ? rowAmpSize
      : columnAmpSize
    : {
        width: '100%',
        height: ['190px', '145px', '190px', '145px'],
      }

  return (
    <Container
      flexDirection={direction}
      className={props.className}
      data-testid={props.testId}
      headingSize={props.headingSize}
      centerContent={props.centerContent}
      alignment={props.alignment}
    >
      <ArticlePreviewImage
        src={props.image.src}
        alt={props.image.alt ? props.image.alt : ''}
        isAmp={isAmp}
        ampLayout="responsive"
        imageStyle="cover"
        __dangerouslyDisableImageStyleIE11Fix
        lazy={props.image.lazy}
        onClick={() => window.location.assign(props.articleUrl)}
        crossOrigin={props.image.crossOrigin}
        className={props.image.className}
        {...widthHeight}
        height={props.image.height || widthHeight.height}
      />

      <TextContainer
        centerContent={props.centerContent}
        direction={props.direction}
        headingSize={props.headingSize}
      >
        <CategoryLink
          sizing="regular"
          emphasis="low"
          renderedAs="a"
          href={props.categoryUrl}
          headingSize={props.headingSize}
        >
          {props.category}
        </CategoryLink>
        <ArticleTitleContainer href={props.articleUrl}>
          <ArticleTitle
            onClick={() => window.location.assign(props.articleUrl)}
          >
            {props.title}
          </ArticleTitle>
        </ArticleTitleContainer>
        {props.description && (
          <ArticleDescription>{props.description}</ArticleDescription>
        )}
        <BlockAttributes
          attributes={[props.articleAge, articleAuthorLink]}
        ></BlockAttributes>
      </TextContainer>
    </Container>
  )
}
