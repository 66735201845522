import * as React from 'react'

import { NavigationItem, StyledContentHeader, Wrapper } from './style'
import { ContentHeaderSearch } from './ContentHeaderSearch'
import { ContentHeaderSearchProps } from './ContentHeaderSearch/ContentHeaderSearch'
import {
  CarouselButtonStyle,
  HorizontalScrollWrapper,
} from '@thg-commerce/gravity-patterns/HorizontalScrollWrapper'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'
import { useTheme } from '../theme'

export interface NavigationItem {
  text: string
  path: string
}

export interface ContentHeaderI18nText {
  latest: string
  ariaLabel: string
}

export interface ContentHeaderProps {
  navigationStructure?: NavigationItem[]
  isAmp: boolean
  contentHeaderSearch: ContentHeaderSearchProps
  i18nText: ContentHeaderI18nText
  contentHomeURI: string
  backgroundColour?: string
}

export const ContentHeader = (props: ContentHeaderProps) => {
  const theme = useTheme()
  const renderNavigationItems =
    props.navigationStructure &&
    props.navigationStructure.map((item: NavigationItem, index: number) => (
      <li key={index} style={{ margin: '0' }}>
        <NavigationItem href={item.path}>{item.text}</NavigationItem>
      </li>
    ))

  return (
    <StyledContentHeader aria-label={props.i18nText.ariaLabel}>
      <Wrapper>
        {props.navigationStructure && (
          <HorizontalScrollWrapper
            backgroundColour={theme.patterns.contentHeader.backgroundColor}
            buttonStyle={CarouselButtonStyle.OPACITY}
            alignment={HorizontalAlignment.LEFT}
          >
            <li style={{ margin: '0' }}>
              <NavigationItem href={props.contentHomeURI}>
                {props.i18nText.latest}
              </NavigationItem>
            </li>

            {renderNavigationItems}
          </HorizontalScrollWrapper>
        )}
        <ContentHeaderSearch {...props.contentHeaderSearch} />
      </Wrapper>
    </StyledContentHeader>
  )
}
