import * as React from 'react'

import { Button } from '@thg-commerce/gravity-elements'
import { styled, Text, spacing } from '@thg-commerce/gravity-theme'

export interface ShowMoreButtonProps {
  offset: number
  limit: number
  onShowMore: (newOffset: number) => void
  i18nText: {
    showMore: string
  }
}

const StyledButton = styled(Button)`
  ${Text('bodyText', 'alternate')};
  text-transform: none;
`

const Container = styled.div`
  text-align: center;
  margin-top: ${spacing(5)};
`

export const ShowMoreButton = (props: ShowMoreButtonProps) => {
  return (
    <Container>
      <StyledButton
        emphasis="medium"
        sizing="regular"
        onClick={() => {
          props.onShowMore(props.offset + props.limit)
        }}
        data-testid="show-more-button"
      >
        {props.i18nText.showMore}
      </StyledButton>
    </Container>
  )
}
