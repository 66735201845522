import * as React from 'react'
import { useAmp } from 'next/amp'

import {
  getContentRoutePathname,
  i18n,
  Routes,
} from '@thg-commerce/enterprise-core'
import { ContentHeader } from '@thg-commerce/gravity-patterns'
import { ContentHeaderSearchProps } from '@thg-commerce/gravity-patterns/ContentHeader/ContentHeaderSearch/ContentHeaderSearch'

import { useSiteDefinition } from '../../ConfigurationLoader'
import { FullHeaderLayout } from '../'

export const ContentHeaderLayout = ({
  children,
  pageLayoutData,
  pageProps,
}: {
  children: React.ReactNode
  pageLayoutData?: any
  pageProps?: { blogNavigation: any }
}) => {
  const isAmp = useAmp()
  const { contentRoutePrefix } = useSiteDefinition()

  const i18nText = {
    latest: i18n('contentheader.latest.text'),
    ariaLabel: i18n('contentheader.arialabel'),
  }

  const searchUrl = getContentRoutePathname(
    contentRoutePrefix,
    Routes.BlogSearch,
  )
  const contentHeaderSearchProps: ContentHeaderSearchProps = {
    I18nText: {
      buttonTitle: i18n('header.contentsearchbutton.title'),
      formPlaceholder: i18n('header.contentsearchform.placeholder'),
      formTitle: i18n('header.contentsearchform.title'),
    },
    searchUri:
      searchUrl.charAt(searchUrl.length - 1) === '/'
        ? searchUrl.slice(0, -1)
        : searchUrl,
  }

  return (
    <FullHeaderLayout
      features={{
        stickyHeader: false,
        showStripBanner: false,
        wishlistNotification: false,
      }}
      beforeMainContent={
        <ContentHeader
          navigationStructure={pageProps.blogNavigation}
          isAmp={isAmp}
          contentHeaderSearch={contentHeaderSearchProps}
          i18nText={i18nText}
          contentHomeURI={getContentRoutePathname(
            contentRoutePrefix,
            Routes.Blog,
          )}
        />
      }
      pageLayoutData={pageLayoutData}
      $window={typeof window !== 'undefined' ? window : undefined}
    >
      {children}
    </FullHeaderLayout>
  )
}
