import { spacing, Text, mq } from '@thg-commerce/gravity-theme'
import { styled } from '../theme'

const borderWidth = 2

export const StyledContentHeader = styled.nav`
  position: sticky;
  top: 0;
  background-color: ${(props) =>
    props.theme.patterns.contentHeader.backgroundColor};
  z-index: 2;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing(2)} 0;
  align-items: center;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    max-width: ${(props) => props.theme.site.siteWidth};
    margin: 0 auto;
    padding: ${spacing(2)} ${spacing(4)} ${spacing(2)} ${spacing(2)};
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`

export const Navigation = styled.ul`
  display: flex;
  list-style: none;
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    margin: 0;
  }
`

export const NavigationItem = styled.a<{ active?: boolean }>`
  ${(props) =>
    props.active ? Text('bodyText', 'alternate') : Text('bodyText', 'default')}
  display: block;
  padding: ${spacing(1)} calc(${spacing(2)} - ${borderWidth}px);
  border: ${borderWidth}px solid transparent;
  text-decoration: none;
  line-height: 1.3;
  color: ${(props) => props.theme.patterns.contentHeader.textColor};

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.patterns.contentHeader.textColor};
  }

  &:hover {
    text-decoration: underline;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    line-height: 1.3;
  }
`
