import { styled, spacing, mq, Text } from '@thg-commerce/gravity-theme'

export const SearchContainer = styled.div`
  width: 100%;
  max-width: 343px;
  padding: 0 ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    max-width: 269px;
    padding: 0 0 0 ${spacing(2)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    max-width: 310px;
    box-sizing: content-box;
  }
`

export const SearchForm = styled.form`
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.palette.greys.white};
`

export const SearchInput = styled.input`
  ${Text('bodyText', 'default')}
  font-size: 16px;
  flex-grow: 1;
  border: none;
  background: transparent;
  padding: ${spacing(1)};
  border: 2px solid transparent;

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.palette.greys.darker};
  }
`

export const SearchButton = styled.button`
  width: 36px;
  height: 36px;
  border: 2px solid transparent;

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.palette.greys.darker};
  }
`

export const HiddenLabel = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0px;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
`
