import * as React from 'react'
import loadable from '@loadable/component'

const Search = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SearchIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
import { VisuallyHidden } from '@thg-commerce/gravity-elements'

import {
  SearchContainer,
  SearchForm,
  SearchButton,
  SearchInput,
  HiddenLabel,
} from './style'

export interface ContentHeaderSearchProps {
  I18nText: Searchi18nText
  searchUri: string
}

export interface Searchi18nText {
  formTitle: string
  formPlaceholder: string
  buttonTitle: string
}

export const ContentHeaderSearch = ({
  I18nText: { formTitle, formPlaceholder, buttonTitle },
  searchUri,
}: ContentHeaderSearchProps) => {
  const inputRef = React.createRef<HTMLInputElement>()

  const { v4: uuid } = require('uuid')
  const searchUuid = uuid()

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (inputRef.current && inputRef.current.value.length < 1) {
      e.preventDefault()
    }
    return
  }

  return (
    <SearchContainer>
      <SearchForm action={searchUri} method="GET" onSubmit={handleSearchSubmit}>
        <HiddenLabel htmlFor={`article-search-${searchUuid}`}>
          {formPlaceholder}
        </HiddenLabel>
        <SearchInput
          placeholder={formPlaceholder}
          title={formTitle}
          ref={inputRef}
          id={`article-search-${searchUuid}`}
          type="text"
          name="search"
        />
        <SearchButton type="submit" title={buttonTitle}>
          <VisuallyHidden text={buttonTitle} />
          <Search />
        </SearchButton>
      </SearchForm>
    </SearchContainer>
  )
}
